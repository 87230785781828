import moment from 'moment';

export const getUpcomingExhibitions = (exhibitions) => exhibitions
  .filter((exhibition) => moment(new Date(exhibition.dateFrom)).isAfter(moment(), 'day'))
  .sort((a, b) => {
    if (moment(new Date(a.dateFrom)).isAfter(new Date(b.dateFrom), 'day')) {
      return 1;
    }
    if (moment(new Date(b.dateFrom)).isAfter(new Date(a.dateFrom), 'day')) {
      return -1;
    }
    return 0;
  });
