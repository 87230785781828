import moment from 'moment';
import setTime from './setTime';

export const getCurrentEvents = (events) => events
  .filter(
    (event) => event.date
      && (moment(setTime(
        event.date,
        event.startTime,
      )).isBefore(new Date()))
    && (moment(setTime(
      event.date,
      event.endTime,
    )).isAfter(new Date())),
  ).sort((a, b) => {
    if (
      moment(setTime(a.date, a.startTime)).isBefore(setTime(b.date, b.startTime))
    ) {
      return 1;
    }
    if (
      moment(setTime(a.date, a.startTime)).isAfter(setTime(b.date, b.startTime))
    ) {
      return -1;
    }
    return 0;
  });
