import React from 'react';
import { string, number, func } from 'prop-types';
import moment from 'moment';

import './MenuEvent.scss';
import { getFormattedTime } from '../../utils/getFormatedTime';

const MenuEvent = ({
  id,
  name,
  title,
  type,
  date,
  startTime,
  endTime,
  handleSelectEvent,
  setIsSidebarOpen,
  language,
}) => (
  <div className="menu-event">
    {name ? (
      <>
        <h2
          className="menu-event__name"
          dangerouslySetInnerHTML={{ __html: name }}
          onClick={() => {
            setIsSidebarOpen(false);
            handleSelectEvent(id);
          }}
        />
        {title && (
          <h3
            className="menu-event__title"
            dangerouslySetInnerHTML={{ __html: title }}
            onClick={() => {
              setIsSidebarOpen(false);
              handleSelectEvent(id);
            }}
          />
        )}
      </>
    ) : (
      <>
        {title && (
          <h3
            className="menu-event__title menu-event__title-bold"
            dangerouslySetInnerHTML={{ __html: title }}
            onClick={() => {
              setIsSidebarOpen(false);
              handleSelectEvent(id);
            }}
          />
        )}
      </>
    )}

    {type && (
      <h3
        className="menu-event__type"
        dangerouslySetInnerHTML={{ __html: `(${type})` }}
      />
    )}

    {date && (
      <h3 className="menu-event__date">
        {`${moment(date, 'YYYY-MM-DD').format('MMM D, YYYY')}.${startTime && endTime ? ` ${getFormattedTime(startTime)}-${getFormattedTime(endTime)}` : ''}`}
      </h3>
    )}
  </div>
);

MenuEvent.propTypes = {
  id: number,
  name: string,
  title: string,
  type: string,
  date: string,
  startTime: string,
  endTime: string,
  handleSelectEvent: func.isRequired,
  setIsSidebarOpen: func.isRequired,
};

MenuEvent.defaultProps = {
  id: null,
  name: '',
  title: '',
  type: '',
  date: '',
  startTime: '',
  endTime: '',
};

export default MenuEvent;
