import React from 'react';

import { func } from 'prop-types';

import logo from '../../assets/icons/logo.svg';
import menu from '../../assets/icons/menu2.svg';
import './Header.scss';

const Header = ({ setIsSidebarOpen, setIsSelectedLogo }) => (
  <header className="header">
    <div className="header__logo-container">
      <img
        src={logo}
        alt="logo"
        onClick={() => setIsSelectedLogo(true)}
      />
    </div>
    <div className="header__menu-container">
      <img
        className="mobile-menu"
        src={menu}
        alt="menu"
        onClick={() => setIsSidebarOpen(true)}
      />
    </div>
  </header>
);

Header.propTypes = {
  setIsSidebarOpen: func,
  setIsSelectedLogo: func,
};

Header.defaultProps = {
  setIsSidebarOpen: () => {},
  setIsSelectedLogo: () => {},
};

export default Header;
