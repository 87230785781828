import moment from 'moment';

export const getPreviousExhibitions = (exhibitions) => exhibitions.filter((item) => {
  if (item.dateTo) {
    if (moment().isAfter(new Date(item.dateTo))
      && !(moment().isSame(new Date(item.dateTo), 'day'))) {
      return true;
    }
  }
  if (!item.dateTo) {
    if (moment().isAfter(new Date(item.dateFrom))
      && !(moment().isSame(new Date(item.dateFrom), 'day'))) {
      return true;
    }
  }
  return false;
}).sort((a, b) => {
  if (new Date(a.dateTo) < new Date(b.dateTo)) {
    return 1;
  }
  if (new Date(a.dateTo) > new Date(b.dateTo)) {
    return -1;
  }
  return 0;
});
