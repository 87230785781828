import moment from 'moment';

export const getCurrentExhibitions = (exhibition) => exhibition.filter((item) => {
  if (item.dateTo) {
    if (
      (moment().isSame(new Date(item.dateFrom), 'day') || moment().isSame(new Date(item.dateTo), 'day')) ||
      (moment().isAfter(new Date(item.dateFrom))
        && moment(new Date(item.dateTo)).isAfter(moment()))
    ) {
      return true;
    }
  }

  if (!item.dateTo) {
    if (moment(new Date(item.dateFrom)).isSame(moment(), 'day')) {
      return true;
    }
  }
  return false;
}).sort((a, b) => {
  if (new Date(a.dateTo) < new Date(b.dateTo)) {
    return 1;
  }
  if (new Date(a.dateTo) > new Date(b.dateTo)) {
    return -1;
  }
  return 0;
});
