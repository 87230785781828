import React, { useState } from 'react';
import Slider from 'react-slick';
import { object, string } from 'prop-types';

import { BASE_URL } from '../../constants';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.scss';

const SliderComponent = ({ pictures, orientation }) => {
  const [index, setIndex] = useState(0);
  let picturesMapped = [];
  if (pictures.data != null) {
    picturesMapped = pictures.data.map((d) => ({ ...d.attributes, id: d.id }));
  }

  return (
    <>
      <div className={`slider-desktop ${orientation}`}>
        <Slider
          infinite
          adaptiveHeight
          speed={0}
          fade
          arrows
          slidesToShow={1}
          slidesToScroll={1}
          style={{ display: 'grid' }}
          className="customized-slider"
          afterChange={(page) => setIndex(page)}
        >
          {picturesMapped.map((image) => (
            <div key={image.id}>
              <img
                alt={image.name}
                src={`${BASE_URL}${image.url}`}
                className="customized-slider__image"
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className={`slider-mobile ${orientation}`}>
        <Slider
          infinite
          adaptiveHeight
          speed={500}
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
          style={{ display: 'grid' }}
          className="customized-slider"
          afterChange={(page) => setIndex(page)}
        >
          {picturesMapped.map((image) => (
            <div key={image.id}>
              <img
                alt={image.name}
                src={`${BASE_URL}${image.url}`}
                className="customized-slider__image"
              />
            </div>
          ))}
        </Slider>
      </div>

      {picturesMapped.length > 1 && (
        <div className="images-quantity">
          {index + 1}/{picturesMapped.length}
        </div>
      )}

      {picturesMapped.filter((item, i) => i === index).map((image) => (
        <div key={image.id}>
          {image.caption && (
            <h2
              className="customized-slider__caption"
              dangerouslySetInnerHTML={{ __html: image.caption }}
            />
          )}
        </div>
      ))}
    </>
  );
};

SliderComponent.propTypes = {
  pictures: object,
  orientation: string,
};

SliderComponent.defaultProps = {
  pictures: {},
  orientation: 'vertical',
};

export default SliderComponent;
