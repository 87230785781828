export default (dateStr, startTime) => {
  const date = new Date(dateStr);
  if (startTime) {
    const hours = startTime?.split(':')?.[0];
    const minutes = startTime?.split(':')?.[0];
    return new Date(date
      .setHours(hours ? (+`${+hours[0] || ''}${+hours[1]}`) : 0, minutes ? (+`${+minutes[0] || ''}${+minutes[1]}`) : 0, 0, 0));
  }
  return new Date(date.setHours(0, 0, 0, 0));
};
