import moment from 'moment';

import setTime from './setTime';

export const getPreviousEvents = (events) => events
  .filter((event) => {
    if (event.date) {
      return moment(setTime(event.date, event.endTime)).isBefore(new Date());
    }
    return null;
  })
  .sort((a, b) => {
    if (
      moment(setTime(a.date, a.startTime)).isAfter(setTime(b.date, b.startTime))
    ) {
      return -1;
    }
    if (
      moment(setTime(a.date, a.startTime)).isBefore(setTime(b.date, b.startTime))
    ) {
      return 1;
    }
    return 0;
  });
