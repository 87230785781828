import React, { useEffect, useRef } from 'react';
import { any, func, bool } from 'prop-types';

import './Form.scss';

const Form = ({
  status,
  message,
  onValidated,
  notify,
  setIsActive,
  isValid,
  setIsValid,
}) => {
  const formRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (status === 'success') {
      notify();
      setTimeout(
        () => {
          setIsActive(false);
          setIsValid(false);
        },
        5000,
      );
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const { value } = inputRef.current;
    if (value.indexOf('@') > -1) {
      onValidated({
        EMAIL: value,
      });
    }
  };

  const validateForm = (event) => {
    const { value } = event.target;
    if (formRef.current.checkValidity() && !!value.length) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className="form"
      >
        <input
          ref={inputRef}
          type="email"
          placeholder="Type email"
          onChange={validateForm}
        />
        <br />
        {isValid && (
          <button type="submit">
            ► Sign up
          </button>
        )}
      </form>
      {status === 'sending' && <div className="form__message">sending...</div>}
      {status === 'error' && (
        <div className="form__message" dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === 'success' && (
        <div className="form__message" dangerouslySetInnerHTML={{ __html: message }} />
      )}
    </>
  );
};

Form.propTypes = {
  status: any,
  message: any,
  onValidated: func,
  notify: func.isRequired,
  setIsActive: func.isRequired,
  isValid: bool.isRequired,
  setIsValid: func.isRequired,
};

Form.defaultProps = {
  status: null,
  message: null,
  onValidated: () => {},
};

export default Form;
