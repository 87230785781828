import React, { useState, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { array, func } from 'prop-types';
import moment from 'moment';

import MenuExhibition from '../MenuExhibition/MenuExhibition';
import MenuEvent from '../MenuEvent/MenuEvent';
import Form from '../Form/Form';

import 'react-toastify/dist/ReactToastify.css';
import './Menu.scss';

const url = 'https://ssiimmiiaann.us17.list-manage.com/subscribe/post?u=f46edf78047e91decbd5ffa47&amp;id=296f45d478';

const Menu = ({
  currentExhibitions,
  previousExhibitions,
  upcomingExhibitions,
  upcomingEvents,
  handleSelectExhibition,
  setIsSidebarOpen,
  handleSelectAbout,
  handleSelectEvent,
  handleSelectEvents,
  staticText,
  language,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const upcomingEventsAndExhibitions = useMemo(
    () => [
      ...(upcomingExhibitions.map((item) => ({
        isExhibition: true,
        ...item,
      }))),
      ...(upcomingEvents.map((item) => ({ isEvent: true, ...item }))),
    ].sort((a, b) => {
      if (
        moment((a.isExhibition && a.dateFrom) || (a.isEvent && a.date), 'YYYY-MM-DD')
          .isAfter(moment((b.isExhibition && b.dateFrom) || (b.isEvent && b.date), 'YYYY-MM-DD'))
      ) {
        return 1;
      }
      if (
        moment((b.isExhibition && b.dateFrom) || (b.isEvent && b.date), 'YYYY-MM-DD')
          .isAfter(moment((a.isExhibition && a.dateFrom) || (a.isEvent && a.date), 'YYYY-MM-DD'))
      ) {
        return -1;
      }
      return 0;
    }),
    [upcomingEvents, upcomingExhibitions],
  );

  const notify = () => toast('Successfully subscribed to list Simian');

  return (
    <div className="menu">
      <div className="menu__container">
        <div className="menu__exhibitions-container">
          {!!currentExhibitions.length && (
            <div className="menu__current">
              <h1 className="menu__period">{staticText.sidebarCurrent}</h1>
              {currentExhibitions.map((event) => (
                <MenuExhibition
                  key={event.id}
                  id={event.id}
                  name={event.name}
                  title={event.title}
                  type={event.type}
                  dateFrom={event.dateFrom}
                  dateTo={event.dateTo}
                  handleSelectExhibition={handleSelectExhibition}
                  setIsSidebarOpen={setIsSidebarOpen}
                  language={language}
                />
              ))}
            </div>
          )}

          {!!upcomingEventsAndExhibitions.length && (
            <div className="menu__upcoming">
              <h1 className="menu__period">{staticText.sidebarUpcomming}</h1>
              {upcomingEventsAndExhibitions.map(
                ({ isEvent, isExhibition, ...rest }) => (isEvent && (
                  <MenuEvent
                    key={`upcoming-event-${rest.id}`}
                    id={rest.id}
                    name={rest.name}
                    title={rest.title}
                    type={rest.type}
                    startTime={rest.startTime}
                    endTime={rest.endTime}
                    date={rest.date}
                    handleSelectEvent={handleSelectEvent}
                    setIsSidebarOpen={setIsSidebarOpen}
                    language={language}
                  />
                ))
                  || (isExhibition && (
                    <MenuExhibition
                      key={`upcoming-exhibition-${rest.id}`}
                      id={rest.id}
                      name={rest.name}
                      title={rest.title}
                      type={rest.type}
                      dateFrom={rest.dateFrom}
                      dateTo={rest.dateTo}
                      handleSelectExhibition={handleSelectExhibition}
                      setIsSidebarOpen={setIsSidebarOpen}
                      language={language}
                    />
                  )),
              )}
            </div>
          )}

          {!!previousExhibitions.length && (
            <div className="menu__previous">
              <h1 className="menu__period">{staticText.sidebarPrevious}</h1>
              <MenuExhibition
                key={previousExhibitions[0].id}
                id={previousExhibitions[0].id}
                name={previousExhibitions[0].name}
                title={previousExhibitions[0].title}
                type={previousExhibitions[0].type}
                dateFrom={previousExhibitions[0].dateFrom}
                dateTo={previousExhibitions[0].dateTo}
                handleSelectExhibition={handleSelectExhibition}
                setIsSidebarOpen={setIsSidebarOpen}
                language={language}
              />
              {previousExhibitions[1] && (
                <MenuExhibition
                  key={previousExhibitions[1].id}
                  id={previousExhibitions[1].id}
                  name={previousExhibitions[1].name}
                  title={previousExhibitions[1].title}
                  type={previousExhibitions[1].type}
                  dateFrom={previousExhibitions[1].dateFrom}
                  dateTo={previousExhibitions[1].dateTo}
                  handleSelectExhibition={handleSelectExhibition}
                  setIsSidebarOpen={setIsSidebarOpen}
                  language={language}
                />
              )}
              {previousExhibitions.length > 2 && (
                <div className="menu__btn-container">
                  <button
                    type="button"
                    className={isOpen ? 'read-less' : 'read-more'}
                    onClick={() => setIsOpen((prevState) => !prevState)}
                  >
                    {isOpen ? staticText.sidebarSeeLess : staticText.sidebarSeeMore }
                  </button>
                  {isOpen
                    && previousExhibitions
                      .filter((item, index) => index > 1)
                      .map((event) => (
                        <MenuExhibition
                          key={event.id}
                          id={event.id}
                          name={event.name}
                          title={event.title}
                          type={event.type}
                          dateFrom={event.dateFrom}
                          dateTo={event.dateTo}
                          handleSelectExhibition={handleSelectExhibition}
                          setIsSidebarOpen={setIsSidebarOpen}
                          language={language}
                        />
                      ))}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="menu__media-container">
          <h1
            className="menu__about"
            onClick={() => {
              setIsSidebarOpen(false);
              handleSelectEvents(true);
            }}
          >
            {staticText.sidebarEvents}
          </h1>
          <h1
            className="menu__about"
            onClick={() => {
              setIsSidebarOpen(false);
              handleSelectAbout(true);
            }}
          >
            {staticText.sidebarAbout}
          </h1>

          <div className="menu__newsletter">
            {isActive ? (
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <Form
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                    notify={notify}
                    setIsActive={setIsActive}
                    isValid={isValid}
                    setIsValid={setIsValid}
                  />
                )}
              />
            ) : (
              <h1
                onClick={() => setIsActive((prevState) => !prevState)}
              >
               {staticText.sidebarJoinMailingList}
              </h1>
            )}
            <ToastContainer
              className="toast-container"
              position="top-center"
              closeButton={false}
              autoClose={4000}
              hideProgressBar
              draggable
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  currentExhibitions: array,
  previousExhibitions: array,
  upcomingExhibitions: array,
  upcomingEvents: array,
  handleSelectExhibition: func.isRequired,
  handleSelectEvent: func.isRequired,
  handleSelectEvents: func.isRequired,
  setIsSidebarOpen: func.isRequired,
  handleSelectAbout: func.isRequired,
};

Menu.defaultProps = {
  currentExhibitions: [],
  previousExhibitions: [],
  upcomingExhibitions: [],
  upcomingEvents: [],
};

export default Menu;
