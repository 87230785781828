import React from 'react';
import { func, string } from 'prop-types';

import { ENGLISH, DANISH } from '../../constants';

import './LanguageSwitcher.scss';

const isBold = (siteLanguage, elementLanguage) => siteLanguage === elementLanguage ? 'bold' : '';

const LanguageSwitcher = ({ language, setLanguage, hideOnMobile}) => (
  <div className="language-switcher" id={hideOnMobile}>
    <span
      className={isBold(language, DANISH)}
      onClick={() => setLanguage(DANISH)}
    >
      Dansk
    </span> /&nbsp;
    <span
      className={isBold(language, ENGLISH)}
      onClick={() => setLanguage(ENGLISH)}
    >
      English
    </span>
  </div>
);

LanguageSwitcher.propTypes = {
  language: string,
  setLanguage: func,
};

LanguageSwitcher.defaultProps = {
  language: () => {},
  setLanguage: () => {},
};

export default LanguageSwitcher;
