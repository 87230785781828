import React, { useEffect, useRef } from 'react';
import { bool, func, object } from 'prop-types';

import './About.scss';

const About = ({
  about,
  isSelectedAbout,
  handleSelectAbout,
  staticText,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (isSelectedAbout) {
      ref.current.scrollIntoView();
    }
    handleSelectAbout(false);
  });

  useEffect(() => {
    const a = document.getElementsByTagName('a');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < a.length; i++) {
      a[i].setAttribute('target', '_blank');
      a[i].setAttribute('rel', 'noreferrer noopener');
    }
  });

  return (
    <div
      ref={ref}
      className="about"
    >
      <div className="about__description">
        {about.aboutSimian && (
          <div className="about__description-item">
            <h1 className="about__title">{staticText.aboutHeader}</h1>
            <h2
              className="about__text"
              dangerouslySetInnerHTML={{ __html: about.aboutSimian }}
            />
          </div>
        )}

        {about.partnersAndSponsors && (
          <div className="about__description-item">
            <h1 className="about__title">{staticText.aboutPartnersAndSponsors}</h1>
            <h2
              className="about__text"
              dangerouslySetInnerHTML={{ __html: about.partnersAndSponsors }}
            />
          </div>
        )}
      </div>

      <div className="about__contacts">
        {about.contacts && (
          <>
            <h1 className="about__title">{staticText.aboutContacts}</h1>
            <h2
              className="about__text"
              dangerouslySetInnerHTML={{ __html: about.contacts }}
            />
          </>
        )}
      </div>

      <div className="about__visit">
        {about.visit && (
          <>
            <h1 className="about__title">{staticText.aboutVisit}</h1>
            <h2
              className="about__text"
              dangerouslySetInnerHTML={{ __html: about.visit }}
            />
          </>
        )}
      </div>
    </div>
  );
};

About.propTypes = {
  about: object.isRequired,
  isSelectedAbout: bool.isRequired,
  handleSelectAbout: func.isRequired,
};

export default About;
