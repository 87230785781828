import React from 'react';
import { string, number, func } from 'prop-types';
import moment from 'moment';
import 'moment/locale/da';
import 'moment/locale/en-gb';

import './MenuExhibition.scss';

const MenuExhibition = ({
  id,
  name,
  title,
  type,
  dateFrom,
  dateTo,
  handleSelectExhibition,
  setIsSidebarOpen,
  language,
}) => {
    if (language) {
        if (language === "da"){
            moment.locale("da")
        } else if (language === "en") {
            moment.locale("en-gb")
        }
    }
     return  (

      <div className="menu-exhibition">
        {name ? (
          <>
            <h2
              className="menu-exhibition__name"
              dangerouslySetInnerHTML={{ __html: name }}
              onClick={() => {
                setIsSidebarOpen(false);
                handleSelectExhibition(id);
              }}
            />
            {title && (
              <h3
                className="menu-exhibition__title"
                dangerouslySetInnerHTML={{ __html: title }}
                onClick={() => {
                  setIsSidebarOpen(false);
                  handleSelectExhibition(id);
                }}
              />
            )}
          </>
        ) : (
          <>
            {title && (
              <h3
                className="menu-exhibition__title menu-exhibition__title-bold"
                dangerouslySetInnerHTML={{ __html: title }}
                onClick={() => {
                  setIsSidebarOpen(false);
                  handleSelectExhibition(id);
                }}
              />
            )}
          </>
        )}

        {dateFrom && (
          <h3 className="menu-exhibition__date">
            {moment(new Date(dateFrom), 'YYYY-MM-DD').format('MMM D')}
            {dateTo && ` — ${moment(new Date(dateTo), 'YYYY-MM-DD').format('MMM D, YYYY')}`}
          </h3>
        )}
      </div>
    );
};
MenuExhibition.propTypes = {
  id: number,
  name: string,
  title: string,
  type: string,
  dateFrom: string,
  dateTo: string,
  handleSelectExhibition: func.isRequired,
  setIsSidebarOpen: func.isRequired,
};

MenuExhibition.defaultProps = {
  id: null,
  name: '',
  title: '',
  type: '',
  dateFrom: '',
  dateTo: '',
};

export default MenuExhibition;
