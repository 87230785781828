import React, { useEffect } from 'react';
import Sidebar from 'react-sidebar';
import {
  arrayOf, bool, func, node, object,
} from 'prop-types';

import Header from '../Header/Header';
import Menu from '../Menu/Menu';

import logo from '../../assets/icons/logo.svg';
import cross from '../../assets/icons/cross.svg';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import './Sidebar.scss';

const SidebarComponent = ({
  children,
  exhibitions,
  isSidebarOpen,
  setIsSidebarOpen,
  setSelectedExhibitionId,
  setSelectedEventId,
  setIsSelectedAbout,
  setIsSelectedEvent,
  setIsSelectedLogo,
  currentExhibitions,
  previousExhibitions,
  upcomingExhibitions,
  upcomingEvents,
  language,
  setLanguage,
  staticText,
}) => {
  useEffect(() => {
    let lastScrollTop = 0;

    const sidebar = document.querySelector('.sidebar');
    const sidebarContent = document.querySelector('.sidebar-content');

    sidebar.addEventListener('scroll', () => {
      const { scrollTop } = sidebar;
      const sidebarNavbar = document.querySelector('.sidebar-header');
      if (scrollTop > lastScrollTop + 50) {
        sidebarNavbar.style.top = '-60px';
      }
      if (scrollTop < lastScrollTop - 50) {
        sidebarNavbar.style.top = '0';
      }
      if (scrollTop < 50) {
        sidebarNavbar.style.top = '0';
      }
      setTimeout(() => {
        lastScrollTop = scrollTop;
      }, 100);
    });

    sidebarContent.addEventListener('scroll', () => {
      const { scrollTop } = sidebarContent;
      const contentNavbar = document.querySelector('.header__container');
      if (scrollTop > lastScrollTop + 50) {
        contentNavbar.style.top = '-60px';
      }
      if (scrollTop < lastScrollTop - 50) {
        contentNavbar.style.top = '0';
      }
      if (scrollTop < 50) {
        contentNavbar.style.top = '0';
      }
      setTimeout(() => {
        lastScrollTop = scrollTop;
      }, 100);
    });
  });

  return (
    <Sidebar
      open={isSidebarOpen}
      sidebarClassName="sidebar"
      contentClassName="sidebar-content"
      overlayClassName="overlay"
      touch={false}
      onSetOpen={() => setIsSidebarOpen((prevValue) => !prevValue)}
      sidebar={(
        <div>
          <div className="sidebar-header">
            <div className="sidebar-header__logo-container">
              <img
                src={logo}
                alt="logo"
                onClick={() => {
                  setIsSelectedLogo(true);
                  setIsSidebarOpen(false);
                }}
              />
            </div>
            <LanguageSwitcher language={language} setLanguage={setLanguage} />
            <div className="sidebar-header__cross-container">
              <button
                type="button"
                onClick={() => setIsSidebarOpen(false)}
              >
                <img src={cross} alt="cross" />
              </button>
            </div>
          </div>
          <Menu
            exhibitions={exhibitions}
            handleSelectExhibition={setSelectedExhibitionId}
            setIsSidebarOpen={setIsSidebarOpen}
            handleSelectAbout={setIsSelectedAbout}
            currentExhibitions={currentExhibitions}
            previousExhibitions={previousExhibitions}
            upcomingExhibitions={upcomingExhibitions}
            upcomingEvents={upcomingEvents}
            handleSelectEvent={setSelectedEventId}
            handleSelectEvents={setIsSelectedEvent}
            staticText={staticText}
            language={language}
          />
        </div>
      )}
    >
      <div>
        <div className="header__container">
          <Header
            setIsSidebarOpen={setIsSidebarOpen}
            setIsSelectedLogo={setIsSelectedLogo}
          />
        </div>
        {children}
      </div>
    </Sidebar>
  );
};

SidebarComponent.propTypes = {
  children: node.isRequired,
  exhibitions: arrayOf(object).isRequired,
  isSidebarOpen: bool.isRequired,
  setIsSidebarOpen: func.isRequired,
  setSelectedExhibitionId: func.isRequired,
  setSelectedEventId: func.isRequired,
  setIsSelectedAbout: func.isRequired,
  setIsSelectedEvent: func.isRequired,
  setIsSelectedLogo: func.isRequired,
  currentExhibitions: arrayOf(object).isRequired,
  previousExhibitions: arrayOf(object).isRequired,
  upcomingExhibitions: arrayOf(object).isRequired,
  upcomingEvents: arrayOf(object).isRequired,
};

export default SidebarComponent;
